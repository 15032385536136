import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import WhyChooseUs from '../components/Demo/WhyChooseUs'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Demo" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/demo" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Request a Demo" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Demo" 
            />
            <WhyChooseUs />
            {/* <ContactForm /> */}
            <Footer />
        </Layout>
    );
}

export default Contact